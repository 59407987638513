import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "interested-in-contributing-to-carbon-this-design-system-is-the-result-of-community-contributions-large-and-small--of-code-design-ideas-and-guidance-heres-how-you-can-contribute-too"
    }}>{`Interested in contributing to Carbon? This design system is the result of community contributions, large and small—of code, design, ideas, and guidance. Here’s how you can contribute too.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Introduction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Working in the open</AnchorLink>
      <AnchorLink mdxType="AnchorLink">The Carbon community</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Project workflow</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "introduction"
    }}>{`Introduction`}</h2>
    <p>{`Carbon is free to use for anybody building a product or website, and the Carbon community is always working to make it better. Contributors like you help to make Carbon great, and so we’re glad you’re here.`}</p>
    <p>{`Contributions are not limited to code. We also encourage feedback, documentation, new designs, and tools.`}</p>
    <p>{`All you need is a `}<a parentName="p" {...{
        "href": "https://github.com/join"
      }}>{`public GitHub account`}</a>{` to get started. Most contributions begin with a GitHub issue using one of these templates:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/issues/new?assignees=&labels=type%3A+question+%3Aquestion%3A&template=question.md&title="
        }}>{`Ask a question`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/issues/new?assignees=&labels=type%3A+bug+%F0%9F%90%9B&template=bug-report.md&title="
        }}>{`Report a bug`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/carbon-design-system/carbon/issues/new?assignees=&labels=type%3A+enhancement+%F0%9F%92%A1&template=feature-request-or-enhancement.md&title="
        }}>{`Request a feature`}</a></li>
    </ul>
    <h2 {...{
      "id": "working-in-the-open"
    }}>{`Working in the open`}</h2>
    <p>{`Carbon lives on GitHub. All work, discussion, issues, bugs, features, improvements, and comments happen right out in the open where everyone can see. As we mentioned, you’ll need a `}<a parentName="p" {...{
        "href": "https://github.com/join"
      }}>{`public GitHub account`}</a>{` to contribute.`}</p>
    <p>{`If you’re looking for something that we don’t have, or you notice something could be improved, you have two options:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Open an issue for the request.`}</strong>{` We’ll review the issue and get back to you with next steps. If we decide the request should be part of our pipeline, we’ll add it to our roadmap. There are never timeline guarentees for requests, but we’ll do our best to give you an estimate.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Begin the work yourself and contribute it back to Carbon.`}</strong>{` We appreciate complete contributions but also works in progress. You’re welcome to open an issue to surface your work and we’ll weigh in and help where we can.`}</p>
      </li>
    </ol>
    <p>{`Regardless of how you choose to contribute, we encourage you to open an issue as early as possible in the process.`}</p>
    <p>{`If working with GitHub sounds like a lot, check out `}<a parentName="p" {...{
        "href": "https://egghead.io/series/how-to-contribute-to-an-open-source-project-on-github"
      }}>{`this free video series`}</a>{`. It’s one of our favorite resources.`}</p>
    <h2 {...{
      "id": "the-carbon-community"
    }}>{`The Carbon community`}</h2>
    <h3 {...{
      "id": "users"
    }}>{`Users`}</h3>
    <p>{`Users are members of the community who use Carbon guidelines, assets, and tooling. Anyone can be a user, and we encourage users to participate in the community as much as possible.`}</p>
    <h3 {...{
      "id": "contributors"
    }}>{`Contributors`}</h3>
    <p>{`Contributors are members of the community who contribute to Carbon in a material way. Anyone can be a contributor. In addition to participating as a user, you can also contribute by:`}</p>
    <ul>
      <li parentName="ul">{`Reporting bugs or missing features through GitHub issues`}</li>
      <li parentName="ul">{`Fixing bugs, adding features, and improving documentation`}</li>
    </ul>
    <h3 {...{
      "id": "maintainers"
    }}>{`Maintainers`}</h3>
    <p>{`Maintainers are members of the community who are committed to the success of individual Carbon projects. In addition to their participation as a contributor, maintainers:`}</p>
    <ul>
      <li parentName="ul">{`Label, close, and manage GitHub issues`}</li>
      <li parentName="ul">{`Close and merge GitHub pull requests`}</li>
    </ul>
    <h2 {...{
      "id": "project-workflow"
    }}>{`Project workflow`}</h2>
    <p>{`Carbon Design System projects typically use a `}<a parentName="p" {...{
        "href": "https://guides.github.com/activities/forking/"
      }}>{`fork and pull request workflow`}</a>{` for contributions. Specific instructions can be found in each project’s GitHub `}<inlineCode parentName="p">{`CONTRIBUTING.md`}</inlineCode>{` file.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      